<template>
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true'>
					<el-form-item  label="">
						<el-radio v-model="filters.reportType" label="1">周报</el-radio>
						<el-radio v-model="filters.reportType" label="2">月报</el-radio>
						<el-radio v-model="filters.reportType" label="3">年报</el-radio>
					</el-form-item>
					<el-form-item  label="日期:" v-if="filters.reportType == '2'||filters.reportType == '3'">
						<el-date-picker
								v-model="filters.monthData"
								type="year"
								placeholder="选择年"
						>
						</el-date-picker>
					</el-form-item>
					<el-form-item  label="日期:" v-if="filters.reportType == '1'">
						<!-- <el-date-picker
								v-model="filters.monthData"
								type="month"
								placeholder="选择月"
						>
						</el-date-picker> -->
						<el-date-picker
								v-model="filters.monthData"
								type="monthrange"
								range-separator="至"
								start-placeholder="开始月份"
								end-placeholder="结束月份"
						>
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<!-- 列表 -->
		<el-table
				:data="tableData"
				border
				style="width: 100%"
				v-loading="listLoading"
				@current-change='currentChange'
				:highlight-current-row='true'
		>
			<el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
			<el-table-column prop="Type" label="简报类型" width="120" align="center">
				<template scope="scope">
					<el-tag type='' v-if='scope.row.Type === 1'>周报</el-tag>
					<el-tag type='success' v-else-if='scope.row.Type === 2'>月报</el-tag>
					<el-tag type='success' v-else-if='scope.row.Type === 3'>年报</el-tag>
					<el-tag type='danger' v-else>未知</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="ReportName" label="名称" min-width="130" align="center" show-overflow-tooltip>
<!--				<template scope="scope">-->
<!--					{{filters.reportType == '2' ? scope.row.ReportObjectName + scope.row.StartDay.split("-")[0] + '年' + scope.row.CategoryIndex + '月数据统计月报' : scope.row.ReportObjectName + scope.row.StartDay.split("-")[0] + '年' + scope.row.CategoryIndex + '周数据统计周报'}}-->
<!--				</template>-->
			</el-table-column>
			<el-table-column prop="Year" :label="filters.reportType == '2' ? '月（起止日期）' : (filters.reportType == '1'?'周（起止日期）':'年（起止日期）')" min-width="110" align="center">
				<template scope="scope">
					<div >
						{{scope.row.ReportYearMonthWeek}}
					</div>
					<div >
						{{scope.row.StartReportDate.split(" ")[0]}} 至 {{scope.row.EndReportDate.split(" ")[0]}}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="ReportAbstract" label="简报摘要" min-width="130" align="center" show-overflow-tooltip></el-table-column>
			<!-- <el-table-column prop="CreateTime" :formatter="formatCreateTime" label="生成时间" min-width="100" align="center"></el-table-column> -->
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar" style="height: 80px;margin-top: 12px">
				<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="pages.pageIndex"
						:page-sizes="pages.pageArr"
						:page-size="pages.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="pages.dataCount">
				</el-pagination>
		</el-col>
	</div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
	getAssessmentReport,
	getAreaDictionaryList,
	downloadAssemenetReport,
	getProjectReportListPage,
	downloadProjectReport, getProjectInformation
} from '@/api/api'
import axios from "axios";
import Qs from 'qs'
export default {
	components:{Toolbar},
	data() {
		return {
			buttonList: [],
			needData: '',
			filters:{
				reportType: "2",
				yearData: "",
				monthData: "",
			},
			shengArr: [],
			shiArr: [],
			xianArr: [],
			provinceLoading:false,
			cityLoading:false,
			areaLoading:false,
			provinceIsDisabled:false,
			cityIsDisabled:false,
			areaIsDisabled:false,
			userInfoByToken:{},
			tableData: [],
			options:[],
			options1:[
				{CertificateName:'所有证书',ID:null},
				{CertificateName:'待发证',ID:0},
				{CertificateName:'已发证',ID:1},
				{CertificateName:'已撤销',ID:2},
			],
			listLoading: false,
			//关于分页的obj
			pages: {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			ProvinceID: 1919,
			CityID: '',
			RegionID: '',
			TownID: '',
		}
	},
	methods: {
		callFunction(item) {
			this[item.Func].apply(this, item);
		},
		getDataList(){
			this.listLoading = true
			var params = {
				type: this.filters.reportType,
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
			}
			var today = new Date()
			if(this.filters.reportType === "2"||this.filters.reportType === "3") {
				params.date = this.getMonthFirstOrEndDay(3,this.filters.monthData)
						// params.endDate = new Date(this.getMonthFirstOrEndDay(4,this.filters.monthData)) > today ? this.formatCreateTimeDay1(today) : this.getMonthFirstOrEndDay(4,this.filters.monthData)
			} else if (this.filters.reportType === '1'){
				params.date = this.formatCreateTimeDay1(this.getMonthFirstOrEndDay(1,this.filters.monthData[0]))
						// params.endDate = new Date(this.formatCreateTimeDay1(this.getMonthFirstOrEndDay(2,this.filters.monthData[1]))) > today ? this.formatCreateTimeDay1(today) : this.formatCreateTimeDay1(this.getMonthFirstOrEndDay(2,this.filters.monthData[1]))
			}
			// else {
			// 	params.startDate = this.formatCreateTimeDay1(this.getMonthFirstOrEndDay(1,this.filters.monthData[0])),
			// 			params.endDate = new Date(this.formatCreateTimeDay1(this.getMonthFirstOrEndDay(2,this.filters.monthData[1]))) > today ? this.formatCreateTimeDay1(today) : this.formatCreateTimeDay1(this.getMonthFirstOrEndDay(2,this.filters.monthData[1]))
			// }
			getProjectReportListPage(params).then(res => {
				var result = res.data
				if(result.Success){
					this.listLoading = false;
					this.pages.dataCount = result.Response.DataCount
					this.pages.pageIndex = result.Response.PageIndex
					if(result.Response.PageSize > 0){
						this.pages.pageSize = result.Response.PageSize
					}
					this.tableData = result.Response.Data
					console.log(this.tableData,'tbaledata')
				}else{
					this.listLoading = false
				}
			})
		},
		// 查询
		getListData() {
			this.pages.pageIndex = 1
			this.getDataList();
		},
		// 下载
		downloadReport() {
			if(this.needData) {
				// if(this.filters.reportType == "2" || this.needData.Type == 2) {
				//     this.$message.warning("月报暂不支持下载，请切换周报下载！")
				//     return
				// }
				this.$confirm('确定下载简报？',"提示",{}).then(() => {
							var params = {
								type: this.needData.Type,
								startTime:this.needData.StartReportDate,
								endTime:this.needData.EndReportDate,
								projectId:38
							}
							downloadProjectReport(params).then(res => {
								// let name =this.needData.Type === 2 ?(this.needData.StartReportDate.split('-')[0]+'-'+this.needData.StartReportDate.split('-')[1]+ "月份月报") :(this.needData.Type===1?(this.needData.ReportYearMonthWeek+"周报"):(this.needData.StartReportDate.split('-')[0]+ "年年报"))
								var result = res.data
								console.log(res,'resdddd')
								if(result){
									// const aLink = document.createElement("a");
									// let blob = new Blob([res.data], {type: "content-type"})
									// aLink.href = URL.createObjectURL(blob)
									// aLink.setAttribute('download', (this.needData.ReportName+name+ '.docx'))
									// aLink.click()
									this.handleFileDownload(res)
								} else {
									this.$message.error(result.Message || "导出失败！")
								}
							})
						})
						.catch(() => {
						
						})
			} else {
				this.$message.warning("请先选择要下载的数据！")
			}
		},
		handleFileDownload(response) {
			// 从headers中获取文件名（处理UTF-8编码）
			const contentDisposition = response.headers['content-disposition'];
			console.log(contentDisposition,'cccccccc')
			const fileNameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/);
			const fileName = fileNameMatch
					? decodeURIComponent(fileNameMatch[1])
					: 'downloaded_file.xls';
			
			// 创建Blob对象
			let blob = new Blob([response.data], {type: "content-type"})
			
			// 创建临时链接并触发下载
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = fileName;
			link.style.display = 'none';
			
			document.body.appendChild(link);
			link.click();
			
			// 清理资源
			document.body.removeChild(link);
			window.URL.revokeObjectURL(link.href);
		},
		
		// 下载总报
		downloadComplete() {
			let paramse = {
			}
			getProjectInformation(paramse).then(resa => {
				if (resa.data.Success) {
					if (!resa.data.Response.IsArchive){
						this.$confirm('当前项目还未结项，现在下载的报告为从项目开始日期到项目当前日期的项目情况汇总报告，是否继续下载？', "提示", {}).then(() => {
									var params = {
										type: 4,
									}
									downloadProjectReport(params).then(res => {
										var result = res.data
										console.log(res, 'resdddd')
										if (result) {
											const aLink = document.createElement("a");
											let blob = new Blob([res.data], {type: "content-type"})
											aLink.href = URL.createObjectURL(blob)
											aLink.setAttribute('download', (resa.data.Response.ProjectName + '.docx'))
											aLink.click()
											// this.handleFileDownload(res,name)
										} else {
											this.$message.error(result.Message || "导出失败！")
										}
									})
								})
								.catch(() => {
								
								})
					} else {
						this.$confirm('确定下载总报？', "提示", {}).then(() => {
									var params = {
										type: 4,
									}
									downloadProjectReport(params).then(res => {
										var result = res.data
										console.log(res, 'resdddd')
										if (result) {
											const aLink = document.createElement("a");
											let blob = new Blob([res.data], {type: "content-type"})
											aLink.href = URL.createObjectURL(blob)
											aLink.setAttribute('download', (resa.data.Response.ProjectName + '.docx'))
											aLink.click()
											// this.handleFileDownload(res,name)
										} else {
											this.$message.error(result.Message || "导出失败！")
										}
									})
								})
								.catch(() => {
								
								})
					}
				}
			})
		
		},
		// 点击table某一行
		currentChange(val) {
			this.needData = val
		},
		// 省选中
		selectRowChange1(row) {
			if(!row){
				this.shiArr = []
				this.xianArr = []
				this.filters.selecShi = ''
				this.filters.selecXian = ''
			}else{
				this.parentSheng = row
				var params = {
					parentId: this.parentSheng,
					level:1
				}
				this.getPlace(params)
			}
		},
		// 市选中
		selectRowChange2(row) {
			if(!row){
				this.xianArr = []
				this.filters.selecXian = ''
			}else{
				this.parentShi = row
				var params = {
					parentId: this.parentShi,
					level:2
				}
				this.getPlace(params)
			}
		},
		// 获取行政区域
		getPlace(params){
			switch(params.level){
				case 0:
					this.provinceLoading = true
					break;
				case 1:
					this.cityLoading = true
					break;
				case 2:
					this.areaLoading = true
					break
			}
			getAreaDictionaryList(params).then(res => {
				if(res.data.Success){
					switch(params.level){
						case 0:
							this.provinceLoading = false
							this.shengArr = res.data.Response.reverse();
							break;
						case 1:
							this.cityLoading = false
							this.shiArr = res.data.Response.reverse();
							break;
						case 2:
							this.areaLoading = false
							this.xianArr = res.data.Response.reverse();
							break
					}
				}else{return}
			}).catch(() => {})
		},
		// 行政区域选项分页
		pages1Change(value) {
			this.pagesOpt1.pageIndex = value
			var params = {
				level: 0,
			}
			this.getCityPage(params);
		},
		pages1Change2(value) {
			this.pagesOpt2.pageIndex = value
			var params = {
				parentId: this.parentSheng,
			}
			this.getCityPage2(params);
		},
		pages1Change3(value) {
			this.pagesOpt3.pageIndex = value
			var params = {
				parentId: this.parentShi,
			}
			this.getCityPage3(params);
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getDataList();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getDataList();
		},
		// 时间格式化
		formatCreateTime: function(row, column) {
			return !row.CreateTime || row.CreateTime == ""
					? ""
					: util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
		},
		// 筛选项input框清空
		handleClear(){
		
		},
		// 获取当前时间
		getLastSomeDay() {
			var currentDate = new Date();  // 获取当前时间
			var previousDate = new Date(currentDate);  // 复制当前时间对象
			previousDate.setDate(currentDate.getDate());  // 将日期设置为前一天
			// this.filters.monthData = this.formatCreateTimeDay1(previousDate)
			// this.getDataList();
			this.filters.monthData = new Date()
			this.getDataList()
		},
		formatCreateTimeDay1: function(row, column) {
			return !row || row == ""
					? ""
					: util.formatDate.format(new Date(row), "yyyy-MM-dd");
		},
		getMonthFirstOrEndDay(type,val) {
			// 获取当月的第一天
			if(type == 1) {
				var date = val
				date.setDate(1)
				return date.toLocaleDateString()
			}
			// 获取当月的最后一天
			if(type == 2) {
				const date = new Date(val);
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				return year + (month < 10 ? '-0' : '-') + month + '-' + new Date(year, month, 0).getDate();
			}
			// 获取当年第一天
			if(type == 3) {
				var date = val
				return this.formatCreateTimeDay1(date).split("-")[0] + "-01-01"
			}
			// 获取当年最后一天
			if(type == 4) {
				var date = val
				return this.formatCreateTimeDay1(date).split("-")[0] + "-12-31"
			}
		},
		rowClass({columnIndex}){
			return 'padding:8px 0!important;'
		},
	},
	watch: {
		'filters.reportType': function(val) {
			console.log(val,'valllllll')
			if(val === '2'||val ==='3') {
				this.filters.monthData = new Date()
			} else {
				this.filters.monthData = [new Date(),new Date()]
			}
			this.getListData()
		}
	},
	created(){
		var data = JSON.parse(localStorage.getItem("user"))
		this.ProvinceID = data.ProvinceID
		this.CityID = data.CityID
		this.RegionID = data.RegionID
		this.TownID = data.TownID
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	},
	mounted() {
		this.getLastSomeDay()
	}
}
</script>

<style lang="stylus" scoped>

</style>